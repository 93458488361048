import React, { useEffect, useState } from "react"
// import { Link } from "gatsby"

// import {
//   // CalendarIcon,
//   ChevronRightIcon,
//   // UsersIcon,
//   UserGroupIcon,
//   // CollectionIcon,
//   ClipboardListIcon,
//   // SparklesIcon,
//   TagIcon,
// } from '@heroicons/react/outline'

// import Image from "../common/image"
// import CommunityFeatureTags from "../feature/communityfeaturetags"

// import { unslugify, readableNumber } from '../../utils/text'
import { testimonials } from './constants'

import "../../styles/scroller.css"


const ScrollingTestimonials = () => {
  const [fetchingStats, setFetchingStats] = useState(false);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    getStats();
  }, []); // get the stats for the product

  useEffect(() => {
    [...document.querySelectorAll('.scroller .rows .row')].map(column => {
      column.style.setProperty('--animation', 'slide');
      column.style.setProperty('width', '200%');
      column.innerHTML = column.innerHTML + column.innerHTML;
    });
  }, []); // instantiate the scroll


  const getStats = () => {
    if (fetchingStats || stats) return;

    setFetchingStats(true);
    const API_URL = 'https://gummysearch-api.onrender.com/api/v1/landing-stats/'
    const response = fetch(`${API_URL}`, {
      method: "GET",
      headers: {"Authorization": "Token LDqKCZBUmZKhHAFyheQWVxFWu"}
    })
    .then(response => response.json()) // parse JSON from request
    .then(resultData => {
      setStats(resultData)
      setFetchingStats(false);
    })
    .catch((error) => {
      console.error('Error:', error);
      setFetchingStats(false);
    })
  }

  // split it into 2 columns for now
  const rows = [
    {'col': '1', 'items': testimonials.filter((c, index) => index % 3 === 0)},
    {'col': '2', 'items': testimonials.filter((c, index) => index % 3 === 1)},
    {'col': '3', 'items': testimonials.filter((c, index) => index % 3 === 2)}
  ]

  return (
    <>
      <div className="mb-12">
        {fetchingStats || !stats ? (
          <h2 className="font-extrabold text-3xl sm:text-4xl mt-12 text-center">
            10,000's of entrepreneurs & researchers are using GummySearch
          </h2>
        ) : (
          <h2 className="font-extrabold text-3xl sm:text-4xl mt-12 text-center">
            {stats.users} users have explored {stats.subreddits || '50,000+'} subreddits
          </h2>
        )}
        
        <p className="text-desktop-subheading text-slate-body text-center mt-8">
          What they said about GummySearch...
        </p>
      </div>
      <div className="scroller w-full h-screen">
        <div className="rows relative h-full">
          {rows.map((row) => (
            <div className="flex-1 py-2 row relative h-1/3 space-x-4 overflow-hidden whitespace-nowrap relative" key={row.col}>
              {row.items.map((t, index) => (
                <div key={t.link} className="card inline-block h-full rounded-xl p-6 max-w-xs bg-gray-800 text-white overflow-hidden">
                  <div className="flex -mt-3">
                    <p className="-mx-2 text-left opacity-20 text-7xl font-dm-sans italic">
                      "
                    </p>
                    <p className="ml-auto mt-3 text-sm text-slate-body text-right">
                      - <a href={t.link} target="_blank" rel="nofollow" className="border-b border-dashed border-gray-500 hover:border-gray-200 cursor-pointer">{t.name}</a>
                    </p>
                  </div>
                  <div className="whitespace-normal text-base -mt-6">
                    {t.text}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default ScrollingTestimonials;
